body,
h1,
h2,
h3,
ul,
li {
  padding: 0;
  margin: 0;
}
ul {
  list-style: none;
}
