body {
  font-family: "Clear Sans", sans-serif;
}
h1 {
  font-weight: 400;
  font-size: 36px;
}
h2 {
  font-family: "Clear Sans", sans-serif;
  font-size: 18px;
}
.hero h2 {
  color: #7393c1;
  font-weight: 300;
  font-size: 24px;
}
.focus h2 {
  font-size: 18px;
  color: #7393c1;
  font-weight: 300;
}
body {
  background: #f6f7f8;
}
section {
  padding-top: 50px;
  *zoom: 1;
  width: auto;
  max-width: 1100px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
}
section:before,
section:after {
  content: '';
  display: table;
}
section:after {
  clear: both;
}
section p {
  *zoom: 1;
  width: auto;
  max-width: 600px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  color: #9ba6b7;
  font-family: "Clear Sans", sans-serif;
  margin-bottom: 30px;
}
section p:before,
section p:after {
  content: '';
  display: table;
}
section p:after {
  clear: both;
}
header.hero {
  background: #203259;
  color: #fff;
  background: -webkit-linear-gradient(60deg, #225393, #002351);
  background: -moz-linear-gradient(60deg, #225393, #002351);
  background: -o-linear-gradient(60deg, #225393, #002351);
  background: -ms-linear-gradient(60deg, #225393, #002351);
  background: linear-gradient(30deg, #225393, #002351);
}
header.hero section article {
  padding-top: 100px;
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 48.5%;
  margin-left: 0%;
  margin-right: 3%;
}
header.hero section article:before,
header.hero section article:after {
  content: '';
  display: table;
}
header.hero section article:after {
  clear: both;
}
header.hero section article:last-child {
  margin-right: 0%;
}
@media only screen and (min-width: 500px) {
  header.hero section article .content {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 500px) {
  header.hero section article {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  header.hero section article:first-child {
    margin-left: auto;
  }
  header.hero section article:last-child {
    margin-right: auto;
  }
}
header.hero section article h1 {
  margin-bottom: 20px;
  font-weight: 300;
}
header.hero section article .logo {
  display: inline-block;
  width: 134px;
  height: 134px;
  background-image: url("/images/logo.png");
  margin-bottom: 40px;
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  header.hero section article .logo {
    background-image: url("/images/logo@2x.png");
    -webkit-background-size: 134px 134px;
    -moz-background-size: 134px 134px;
    background-size: 134px 134px;
  }
}
header.hero section article ul.actions {
  margin-top: 40px;
  list-style: none;
  font-size: 18px;
  line-height: 2.4;
  display: block;
  zoom: 1;
}
header.hero section article ul.actions:before,
header.hero section article ul.actions:after {
  content: "";
  display: table;
}
header.hero section article ul.actions:after {
  clear: both;
}
@media only screen and (max-width: 500px) {
  header.hero section article ul.actions {
    width: 390px;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 60px;
  }
}
header.hero section article ul.actions li {
  float: left;
  margin-right: 25px;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  cursor: pointer;
}
header.hero section article ul.actions li:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
header.hero section article ul.actions li a {
  display: block;
  line-height: 1.3;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
}
header.hero section article ul.actions li a i.icon {
  display: inline-block;
  vertical-align: middle;
}
header.hero section article ul.actions li a span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
header.hero section article ul.actions li a#icon-iphone i.icon {
  width: 34px;
  height: 49px;
  background-image: url("/images/icon-iphone.png");
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  header.hero section article ul.actions li a#icon-iphone i.icon {
    background-image: url("/images/icon-iphone@2x.png");
    -webkit-background-size: 34px 49px;
    -moz-background-size: 34px 49px;
    background-size: 34px 49px;
  }
}
header.hero section article ul.actions li a#icon-android i.icon {
  width: 30px;
  height: 49px;
  background-image: url("/images/icon-android.png");
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  header.hero section article ul.actions li a#icon-android i.icon {
    background-image: url("/images/icon-android@2x.png");
    -webkit-background-size: 30px 49px;
    -moz-background-size: 30px 49px;
    background-size: 30px 49px;
  }
}
header.hero section article ul.actions li a#icon-mac i.icon {
  width: 65px;
  height: 49px;
  background-image: url("/images/icon-mac.png");
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  header.hero section article ul.actions li a#icon-mac i.icon {
    background-image: url("/images/icon-mac@2x.png");
    -webkit-background-size: 65px 49px;
    -moz-background-size: 65px 49px;
    background-size: 65px 49px;
  }
}
header.hero section article ul.actions li span.soon {
  display: inline-block;
  width: 89px;
  height: 19px;
  margin-bottom: -4px;
  margin-left: 4px;
  background-image: url("/images/soon.png");
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  header.hero section article ul.actions li span.soon {
    background-image: url("/images/soon@2x.png");
    -webkit-background-size: 89px 19px;
    -moz-background-size: 89px 19px;
    background-size: 89px 19px;
  }
}
header.hero section aside {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 48.5%;
  margin-left: 0%;
  margin-right: 3%;
  text-align: center;
}
header.hero section aside:before,
header.hero section aside:after {
  content: '';
  display: table;
}
header.hero section aside:after {
  clear: both;
}
header.hero section aside:last-child {
  margin-right: 0%;
}
@media only screen and (max-width: 500px) {
  header.hero section aside {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  header.hero section aside:first-child {
    margin-left: auto;
  }
  header.hero section aside:last-child {
    margin-right: auto;
  }
}
header.hero section aside #screenshots {
  display: inline-block;
  width: 466px;
  height: 685px;
  margin-bottom: -30px;
  position: relative;
}
header.hero section aside #screenshots > div {
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  background-repeat: no-repeat;
}
header.hero section aside #screenshots #screenshots-iphone {
  width: 466px;
  height: 685px;
  background-image: url("/images/hero_screenshots.png");
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  header.hero section aside #screenshots #screenshots-iphone {
    background-image: url("/images/hero_screenshots@2x.png");
    -webkit-background-size: 466px 685px;
    -moz-background-size: 466px 685px;
    background-size: 466px 685px;
  }
}
header.hero section aside #screenshots #screenshots-android {
  width: 555px;
  height: 685px;
  background-image: url("/images/screenshots_android.png");
  background-repeat: no-repeat;
  background-position: 0 20px;
  left: 50%;
  margin-left: -245px;
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  header.hero section aside #screenshots #screenshots-android {
    background-image: url("/images/screenshots_android@2x.png");
    -webkit-background-size: 491px 616px;
    -moz-background-size: 491px 616px;
    background-size: 491px 616px;
  }
}
header.hero section aside #screenshots #screenshots-mac {
  width: 555px;
  height: 685px;
  background-image: url("/images/screenshots_mac.png");
  background-repeat: no-repeat;
  background-position: 0 50px;
  left: 50%;
  margin-left: -277px;
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  header.hero section aside #screenshots #screenshots-mac {
    background-image: url("/images/screenshots_mac@2x.png");
    -webkit-background-size: 555px 598px;
    -moz-background-size: 555px 598px;
    background-size: 555px 598px;
  }
}
header.hero section aside #screenshots.iphone #screenshots-iphone {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
header.hero section aside #screenshots.android #screenshots-android {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
header.hero section aside #screenshots.mac #screenshots-mac {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
section.planning {
  text-align: center;
  padding-top: 100px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  section.planning {
    padding-top: 60px;
  }
}
section.planning h1 {
  margin-bottom: 40px;
}
section.planning .content {
  *zoom: 1;
}
section.planning .content:before,
section.planning .content:after {
  content: '';
  display: table;
}
section.planning .content:after {
  clear: both;
}
section.planning .content .feature,
section.planning .content .screenshot {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 31.33333333333333%;
  margin-left: 0%;
  margin-right: 3%;
}
section.planning .content .feature:before,
section.planning .content .screenshot:before,
section.planning .content .feature:after,
section.planning .content .screenshot:after {
  content: '';
  display: table;
}
section.planning .content .feature:after,
section.planning .content .screenshot:after {
  clear: both;
}
section.planning .content .feature:last-child,
section.planning .content .screenshot:last-child {
  margin-right: 0%;
}
@media only screen and (max-width: 500px) {
  section.planning .content .feature,
  section.planning .content .screenshot {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  section.planning .content .feature:first-child,
  section.planning .content .screenshot:first-child {
    margin-left: auto;
  }
  section.planning .content .feature:last-child,
  section.planning .content .screenshot:last-child {
    margin-right: auto;
  }
}
section.planning .content .feature h2 {
  color: #274daf;
}
section.planning .content .feature p {
  color: #9ba6b7;
}
@media only screen and (max-width: 500px) {
  section.planning .content .feature {
    text-align: center !important;
  }
  section.planning .content .feature h2,
  section.planning .content .feature p {
    margin: 10px 20px;
  }
}
section.planning .content .feature.left {
  text-align: right;
}
@media only screen and (min-width: 500px) {
  section.planning .content .feature.left {
    padding-top: 100px;
  }
}
section.planning .content .feature.right {
  text-align: left;
}
@media only screen and (min-width: 500px) {
  section.planning .content .feature.right {
    padding-top: 140px;
  }
}
section.planning .content .screenshot img {
  width: 100%;
}
main.focus {
  background: #2a4b77;
}
main.focus section.focus {
  color: #fff;
  padding-bottom: 40px;
  text-align: center;
}
main.focus section.focus h1 {
  margin-bottom: 30px;
}
main.focus section.focus h2 {
  *zoom: 1;
  width: auto;
  max-width: 600px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
}
main.focus section.focus h2:before,
main.focus section.focus h2:after {
  content: '';
  display: table;
}
main.focus section.focus h2:after {
  clear: both;
}
main.focus section.focus .features {
  margin-top: 60px;
  *zoom: 1;
}
main.focus section.focus .features:before,
main.focus section.focus .features:after {
  content: '';
  display: table;
}
main.focus section.focus .features:after {
  clear: both;
}
main.focus section.focus .features .feature {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 31.33333333333333%;
  margin-left: 0%;
  margin-right: 3%;
}
main.focus section.focus .features .feature:before,
main.focus section.focus .features .feature:after {
  content: '';
  display: table;
}
main.focus section.focus .features .feature:after {
  clear: both;
}
main.focus section.focus .features .feature:last-child {
  margin-right: 0%;
}
@media only screen and (max-width: 500px) {
  main.focus section.focus .features .feature {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
  main.focus section.focus .features .feature:first-child {
    margin-left: auto;
  }
  main.focus section.focus .features .feature:last-child {
    margin-right: auto;
  }
}
main.focus section.focus .features .feature h3 {
  font-weight: 300;
  font-size: 24px;
}
main.focus section.focus .features .feature p {
  color: #7393c1;
}
main.focus section.focus .features .feature .screenshot {
  display: inline-block;
  width: 242px;
  height: 302px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 20px rgba(0,0,0,0.2);
  box-shadow: 0 2px 20px rgba(0,0,0,0.2);
}
main.focus section.focus .features .feature .screenshot.screenshot1 {
  background-image: url("/images/focus_screenshot1.png");
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  main.focus section.focus .features .feature .screenshot.screenshot1 {
    background-image: url("/images/focus_screenshot1@2x.png");
    -webkit-background-size: 242px 302px;
    -moz-background-size: 242px 302px;
    background-size: 242px 302px;
  }
}
main.focus section.focus .features .feature .screenshot.screenshot2 {
  background-image: url("/images/focus_screenshot2.png");
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  main.focus section.focus .features .feature .screenshot.screenshot2 {
    background-image: url("/images/focus_screenshot2@2x.png");
    -webkit-background-size: 242px 302px;
    -moz-background-size: 242px 302px;
    background-size: 242px 302px;
  }
}
main.focus section.focus .features .feature .screenshot.screenshot3 {
  background-image: url("/images/focus_screenshot3.png");
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  main.focus section.focus .features .feature .screenshot.screenshot3 {
    background-image: url("/images/focus_screenshot3@2x.png");
    -webkit-background-size: 242px 302px;
    -moz-background-size: 242px 302px;
    background-size: 242px 302px;
  }
}
section.measure {
  text-align: center;
  padding-bottom: 50px;
}
section.measure h1 {
  margin-bottom: 30px;
}
section.measure h2 {
  *zoom: 1;
  width: auto;
  max-width: 600px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  color: #9ba6b7;
  font-weight: 300;
}
section.measure h2:before,
section.measure h2:after {
  content: '';
  display: table;
}
section.measure h2:after {
  clear: both;
}
section.measure .screenshots {
  *zoom: 1;
  width: auto;
  max-width: 760px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  *zoom: 1;
}
section.measure .screenshots:before,
section.measure .screenshots:after {
  content: '';
  display: table;
}
section.measure .screenshots:after {
  clear: both;
}
section.measure .screenshots:before,
section.measure .screenshots:after {
  content: '';
  display: table;
}
section.measure .screenshots:after {
  clear: both;
}
section.measure .screenshots .screenshot {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 48.5%;
  margin-left: 0%;
  margin-right: 3%;
}
section.measure .screenshots .screenshot:before,
section.measure .screenshots .screenshot:after {
  content: '';
  display: table;
}
section.measure .screenshots .screenshot:after {
  clear: both;
}
section.measure .screenshots .screenshot:last-child {
  margin-right: 0%;
}
@media only screen and (max-width: 500px) {
  section.measure .screenshots .screenshot {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  section.measure .screenshots .screenshot:first-child {
    margin-left: auto;
  }
  section.measure .screenshots .screenshot:last-child {
    margin-right: auto;
  }
}
section.measure .screenshots .screenshot .image {
  width: 376px;
  height: 607px;
  display: inline-block;
}
section.measure .screenshots .screenshot .image.image1 {
  background-image: url("/images/stats1.png");
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  section.measure .screenshots .screenshot .image.image1 {
    background-image: url("/images/stats1@2x.png");
    -webkit-background-size: 376px 607px;
    -moz-background-size: 376px 607px;
    background-size: 376px 607px;
  }
}
section.measure .screenshots .screenshot .image.image2 {
  background-image: url("/images/stats2.png");
}
@media all and (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-device-pixel-ratio: 1.5), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  section.measure .screenshots .screenshot .image.image2 {
    background-image: url("/images/stats2@2x.png");
    -webkit-background-size: 376px 607px;
    -moz-background-size: 376px 607px;
    background-size: 376px 607px;
  }
}
main.footer {
  background: #113462;
  color: #e0ebfa;
  font-size: 14px;
  padding-bottom: 20px;
}
main.footer ul.footer-nav {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 22.75%;
  margin-left: 0%;
  margin-right: 3%;
  line-height: 1.5;
  margin-bottom: 30px;
}
main.footer ul.footer-nav:before,
main.footer ul.footer-nav:after {
  content: '';
  display: table;
}
main.footer ul.footer-nav:after {
  clear: both;
}
main.footer ul.footer-nav:last-child {
  margin-right: 0%;
}
@media only screen and (max-width: 500px) {
  main.footer ul.footer-nav {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  main.footer ul.footer-nav:first-child {
    margin-left: auto;
  }
  main.footer ul.footer-nav:last-child {
    margin-right: auto;
  }
}
main.footer ul.footer-nav li {
  padding-left: 20px;
}
main.footer ul.footer-nav li a {
  color: #fff;
  text-decoration: none;
}
main.footer ul.footer-nav li.title {
  color: #7393c1;
  margin-bottom: 15px;
}
