body {
  font-family: "Clear Sans", sans-serif;
}
h1 {
  font-weight: 400;
  font-size: 36px;
}
h2 {
  font-family: "Clear Sans", sans-serif;
  font-size: 18px;
}
.hero h2 {
  color: #7393c1;
  font-weight: 300;
  font-size: 24px;
}
.focus h2 {
  font-size: 18px;
  color: #7393c1;
  font-weight: 300;
}
