@font-face {
  font-family: "Clear Sans";
  font-style: normal;
  font-weight: 100;
  src: local("ClearSans-Thin"), local("Clear Sans Thin"), url("/fonts/clearsans/EOT/ClearSans-Thin.eot"), url("/fonts/clearsans/EOT/ClearSans-Thin.eot?#iefix") format("embedded-opentype"), url("/fonts/clearsans/WOFF/ClearSans-Thin.woff") format("woff"), url("/fonts/clearsans/TTF/ClearSans-Thin.ttf") format("truetype"), url("/fonts/clearsans/SVG/ClearSans-Thin.svg") format("svg");
}
@font-face {
  font-family: "Clear Sans";
  font-style: normal;
  font-weight: 300;
  src: local("ClearSans-Light"), local("Clear Sans Light"), url("/fonts/clearsans/EOT/ClearSans-Light.eot"), url("/fonts/clearsans/EOT/ClearSans-Light.eot?#iefix") format("embedded-opentype"), url("/fonts/clearsans/WOFF/ClearSans-Light.woff") format("woff"), url("/fonts/clearsans/TTF/ClearSans-Light.ttf") format("truetype"), url("/fonts/clearsans/SVG/ClearSans-Light.svg") format("svg");
}
@font-face {
  font-family: "Clear Sans";
  font-style: normal;
  font-weight: 400;
  src: local("ClearSans"), local("Clear Sans"), url("/fonts/clearsans/EOT/ClearSans-Regular.eot"), url("/fonts/clearsans/EOT/ClearSans-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/clearsans/WOFF/ClearSans-Regular.woff") format("woff"), url("/fonts/clearsans/TTF/ClearSans-Regular.ttf") format("truetype"), url("/fonts/clearsans/SVG/ClearSans-Regular.svg") format("svg");
}
@font-face {
  font-family: "Clear Sans";
  font-style: italic;
  font-weight: 400;
  src: local("ClearSans-Italic"), local("Clear Sans Italic"), url("/fonts/clearsans/EOT/ClearSans-Italic.eot"), url("/fonts/clearsans/EOT/ClearSans-Italic.eot?#iefix") format("embedded-opentype"), url("/fonts/clearsans/WOFF/ClearSans-Italic.woff") format("woff"), url("/fonts/clearsans/TTF/ClearSans-Italic.ttf") format("truetype"), url("/fonts/clearsans/SVG/ClearSans-Italic.svg") format("svg");
}
@font-face {
  font-family: "Clear Sans";
  font-style: normal;
  font-weight: 500;
  src: local("ClearSans-Medium"), local("Clear Sans Medium"), url("/fonts/clearsans/EOT/ClearSans-Medium.eot"), url("/fonts/clearsans/EOT/ClearSans-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/clearsans/WOFF/ClearSans-Medium.woff") format("woff"), url("/fonts/clearsans/TTF/ClearSans-Medium.ttf") format("truetype"), url("/fonts/clearsans/SVG/ClearSans-Medium.svg") format("svg");
}
@font-face {
  font-family: "Clear Sans";
  font-style: italic;
  font-weight: 500;
  src: local("ClearSans-MediumItalic"), local("Clear Sans Medium Italic"), url("/fonts/clearsans/EOT/ClearSans-MediumItalic.eot"), url("/fonts/clearsans/EOT/ClearSans-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/fonts/clearsans/WOFF/ClearSans-MediumItalic.woff") format("woff"), url("/fonts/clearsans/TTF/ClearSans-MediumItalic.ttf") format("truetype"), url("/fonts/clearsans/SVG/ClearSans-MediumItalic.svg") format("svg");
}
@font-face {
  font-family: "Clear Sans";
  font-style: normal;
  font-weight: 700;
  src: local("ClearSans-Bold"), local("Clear Sans Bold"), url("/fonts/clearsans/EOT/ClearSans-Bold.eot"), url("/fonts/clearsans/EOT/ClearSans-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/clearsans/WOFF/ClearSans-Bold.woff") format("woff"), url("/fonts/clearsans/TTF/ClearSans-Bold.ttf") format("truetype"), url("/fonts/clearsans/SVG/ClearSans-Bold.svg") format("svg");
}
@font-face {
  font-family: "Clear Sans";
  font-style: italic;
  font-weight: 700;
  src: local("ClearSans-BoldItalic"), local("Clear Sans Bold Italic"), url("/fonts/clearsans/EOT/ClearSans-BoldItalic.eot"), url("/fonts/clearsans/EOT/ClearSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/fonts/clearsans/WOFF/ClearSans-BoldItalic.woff") format("woff"), url("/fonts/clearsans/TTF/ClearSans-BoldItalic.ttf") format("truetype"), url("/fonts/clearsans/SVG/ClearSans-BoldItalic.svg") format("svg");
}
